import React from "react";
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Nav from "../../components/Nav.de"
import Layout from "../../components/layout.de";
import Seo from "../../components/seo";

export const query = graphql`query ThankYouDePageQuery {
  racingPigeon: file(relativePath: {eq: "racing-pigeon.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`

const ThankYou = ({ data }) => (
  <Layout>
    <Seo
      seo={{
        metaTitle: "Thank you",
        metaDescription: "It looks like you got to the thank you page",
      }}
    />
    <Nav />
      <main>
          <div className="mb-10 hero__wrapper hero__wrapper--content-left">
              <div className={"hero mt-8 md:mt-0 mx-auto max-w-screen-3xl"}>
                  <div className={"hero__text p-4 md:p-10 lg:p-16 xl:p-20 2xl:pl-48 md:absolute md:z-10 md:bg-secondary-bg md:w-3/5 xl:w-2/3"}>
                      <h1>Vielen Dank für Ihre Nachricht.</h1>
                      <p>Jetzt passiert Folgendes: Die Brieftaube fliegt zu unserem Büro in Berlin Adlershof. Sie wird an das Fenster klopfen und darauf warten, dass einer unserer Mitarbeiter das Fenster öffnet. Drinnen angekommen, versuchen wir, die Taube auf einen Tisch zu locken, um ihr die Nachricht aus der Kralle zu nehmen. Sie bekommt eine gründliche Kopfmassage und etwas zu trinken und zu essen. Nun werden wir auf Ihre Nachricht antworten.</p>
                  </div>
                  <div className="w-full max-w-screen-md ml-auto hero__image">
                      <GatsbyImage
                        image={data.racingPigeon.childImageSharp.gatsbyImageData}
                        className="min-h-56"
                        alt="TODO" />
                  </div>
              </div>
          </div>
      </main>
  </Layout>
);

export default ThankYou;
